"use client";

import { Disclosure } from "@headlessui/react";
import { AiOutlineMenuFold, AiOutlineCloseCircle } from "react-icons/ai";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import axios from "axios";

const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "Courses", href: "/courses/", current: false },
  // { name: "Event", href: "/events/", current: false },
  { name: "About", href: "/about/", current: false },
  { name: "Blog", href: "/blog/", current: false },
  { name: "We Are Hiring", href: "/career/", current: false },
  { name: "Contact Us", href: "/contact/", current: false },
];

const Navbar = () => {
  const routerPath = usePathname(false);
  
  // let data = {
  //   name: "Tushar",
  //   amount: 1,
  //   number: '9999999999',
  //   MID: 'MID' + Date.now(),
  //   transactionId: 'T' + Date.now(),
  // }
  
  // const handleClick = async () => {
  //   try {
  //     await axios.post('http://localhost:8000/payments', data).then(res => {
  //       console.log(res.data);
  //     }).catch(err => {
  //       console.log(err);
  //     })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  return (
    <div className="bg-white drop-shadow-2xl sticky top-0 z-[10000]">
      <Disclosure
        as="nav"
        className="mx-auto py-4 xm:px-2 md:px-4 lg:px-4 container"
      >
        {({ open }) => (
          <>
            <div className="">
              <div className="flex items-center justify-between">
                <Link href="/">
                  <Image
                    src="/assets/logo/logo_transparent.png"
                    width={150}
                    height={150}
                    alt="vidyastu logo"
                    className="ml-2 md:ml-0"
                  />
                  <p className="mt-4 text-xs tracking-widest uppercase text-blue-800 font-semibold ml-2 md:ml-0">Your Online Finishing School</p>
                </Link>
                {/* mobile menu */}
                <div className="block items-center md:hidden">
                  {/* mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-black hover:bg-yellow focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open Main Menu</span>
                    {open ? (
                      <AiOutlineCloseCircle
                        size={30}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <AiOutlineMenuFold
                        size={30}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                    
                  </Disclosure.Button>
                </div>
                {/* desktop menu items */}
                <div className="hidden md:flex space-x-6 items-center">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      className={`${
                        routerPath === item.href
                          ? "text-green-600 border border-green-600"
                          : "text-black"
                      } px-3 py-2 rounded text-sm font-medium hover:bg-green-100`}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                  {/* <button className="bg-green-600 px-3 py-2 text-white rounded text-sm" onClick={handleClick}>Purchase Course</button> */}
                </div>
              </div>
              {/* mobile menu items  */}
              <Disclosure.Panel className="p-6 space-y-2 md:hidden">
                {navigation.map((item) => (
                  <Link href={item.href} key={item.name}>
                    <Disclosure.Button
                      as="a"
                      aria-current={item.current ? "page" : undefined}
                      className={`${
                        routerPath === item.href
                          ? "text-yellow"
                          : "text-black hover:bg-yellow"
                      } block px-3 py-2 rounded-lg text-base font-medium`}
                    >
                      {item.name}
                    </Disclosure.Button>
                  </Link>
                ))}
                
              </Disclosure.Panel>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Navbar;